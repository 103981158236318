import React from 'react'
import Modal from 'react-bootstrap/Modal';
import ImgComunicado from '../../assets/comunicadoUnico.png'
function MsgAlertas(props) {
    const { msg, show ,callback,imagen} = props;
    return (
        <Modal show={show} contentClassName="bg-transparent "
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header bsPrefix="modal-header m-0 p-0">
                <div className='verPedidoButon w-100 text-center my-auto py-1'
                    style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
                    <div className='text-white textTitle'><i className="fa-solid fa-circle-exclamation"></i> NOTIFICACIÓN</div>
                </div>
            </Modal.Header>
            <Modal.Body bsPrefix="modal-body m-0 p-0 bg-light">
                <div className='container-fluid py-4'>
                {(imagen!=null || imagen!=undefined) &&<div className='container-fluid text-center'>
                    <img className='img-fluid' src={ImgComunicado} width={500}></img>
                </div>}
                {imagen==null&&<p className='textInfo text-center mb-1'><i className="fa-solid fa-triangle-exclamation fa-2xl fa-fade"></i> {msg}</p>}
                </div>
            </Modal.Body>
            <Modal.Footer bsPrefix="modal-footer text-center m-0 p-0 mx-auto bg-light w-100">
                <button className="btn botonNaranja text-light rounded-pill" onClick={() => { callback(true) }}>
                    Aceptar
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default MsgAlertas
